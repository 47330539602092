@font-face {
  font-family: Gilroy;
  src: url("./assets/Fonts/Gilroy\ GEO\ Heavy.ttf");
}

.App{
  position: relative;
  overflow-x: clip;

}


.white-gradient{
  position: absolute;
  width: 20rem;
  height: 20rem;
  background: rgba(255, 255, 255, 0.522);
  filter: blur(100px);
  border-radius: 100px;
}


#light .white-gradient{
  background: rgba(0, 0, 0, 0.522);
}

#dark .white-gradient{
  background: rgba(255, 255, 255, 0.522);
}


