.fpanel-wrapper{
    position: relative;
    padding-bottom: 2rem;
    z-index: 1;
}

.fpanel-container{
    display: flex;
    background: rgba(242, 242, 242, 0.8);
    width: 100%;
    height: 200px;
    justify-content: center;
    align-items: center;
}

#light .fpanel-container{
    background: rgba(242, 242, 242, 0.8);
    transition: 300ms background-color ease-in-out;
}

#dark .fpanel-container{
    background: rgba(33, 33, 33, 0.8);
    transition: 300ms background-color ease-in-out;

}


.searchbar-wrapper{
    display: flex;
    width: 1005px;
    height: 70px;
    justify-content: center;
    align-items: center;
    color: #757575;
    border-radius: 50px;
    user-select: none;
}


#light .searchbar-wrapper{
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    background: #fff;
    color: #757575;
}

#dark .searchbar-wrapper{
    box-shadow: 3px 3px 10px 6px rgba(236, 236, 236, 0.06);
    background: #363636;
    color: #eaeaea;
}


.dropbutton{
    width: 100px;
    height: 40px;
    background-color: rgb(20, 20, 192);
    border-radius: 60px;
    color: white;
    border: none;
    cursor: pointer;
}

.dropbutton:hover{
    background-color: rgb(18, 18, 163);
    transition: 150ms ease-in-out;
}

.searchbutton{
    width: 120px;
    height: 50px;
    border-radius: 50px;
    background-color: blue;
    color: white;
    border: none;
    margin-right: 5px;
}

.searchbutton:hover{
    background-color: rgb(0, 0, 181);
    transition: 150ms ease-in;
    cursor: pointer;
}

.search-input{
    width: 200px;
    height: 50px;
    border: none;
    border-radius: 50px;
}


#light .search-input{
    background: #fff;
    color: #757575;
}


#dark .search-input::placeholder{
    color: #eaeaea;
}

#dark .search-input{
    background: #363636;
    color: #eaeaea;
}

.search-input:focus{
    outline: none;
}

/* Mobile styles --- */
.mobile-searchbar-wrapper{
    display: none;
}




@media (max-width: 768px){
    .searchbar-wrapper{
        display: none;
    }

    .fpanel-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .mobile-searchbar-wrapper{
        display: flex;
        height: 95%;
        width: 95%;
        flex-direction: column;
        border-radius: 10px;
        margin: 10px;
    }

    .mobile-searchbar-wrapper .mobile-searchbar-upper{
        display: flex;
        height: 45px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 0.5px solid rgb(39, 39, 39);
        margin: 3px;
    }


    .mobile-searchbar-wrapper .mobile-searchbar-mid-first{
        display: flex;
        height: 45px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 0.5px solid rgb(39, 39, 39);
        margin: 3px;
    }

    .mobile-searchbar-wrapper .mobile-searchbar-mid-second{
        display: flex;
        height: 45px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 3px;
    }

    .mobile-searchbar-mid-second .left{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 50%;
        border-radius: 10px;
        border: 0.5px solid rgb(39, 39, 39);
        margin-right: 1.5px;
    }

    .mobile-searchbar-mid-second .right{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 50%;
        border-radius: 10px;
        border: 0.5px solid rgb(39, 39, 39);
        margin-left: 1.5px;
    }

    .mobile-searchbar-wrapper .mobile-searchbar-lower{
        display: flex;
        height: 45px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 3px;
    }


    .mobile-searchbar-lower .left{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 60%;
        border-radius: 10px;
        border: 0.5px solid rgb(39, 39, 39);
    }

    .mobile-searchbar-lower .right{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40%;

    }


    .searchbutton{
        width: 120px;
        height: 45px;
        border-radius: 50px;
        background-color: blue;
        color: white;
        border: none;
        margin-right: 5px;
    }
    
    .searchbutton:hover{
        background-color: rgb(0, 0, 181);
        transition: 150ms ease-in;
        cursor: pointer;
    }
    
    .search-input{
        width: 100%;
        height: 43px;
        border-radius: 50px;
        padding: 4px;
    }












    #light .mobile-searchbar-wrapper{
        box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
        background: #fff;
        color: #757575;
    }
    
    #dark .mobile-searchbar-wrapper{
        box-shadow: 3px 3px 10px 6px rgba(236, 236, 236, 0.06);
        background: #363636;
        color: #eaeaea;
    }
    
}