.m-card{
    display: flex;
    gap: 0.6rem;
    padding: 1rem;
    border-radius: 10px;
    max-width: 80%;
    height: 100%;
    margin: auto;
    transition: all 300ms ease-in;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
}



#light .m-card{
    background: rgba(242, 242, 242, 0.8);
}

#dark .m-card{
    background: rgba(33, 33, 33, 0.8);
}


.m-card .img-wrapper {
    display: flex;
    width: 12rem;
    height: 11rem; /* Set the desired height for the image wrapper */
    align-items: center; /* Center the image vertically */
    overflow: hidden; /* Hide any overflowing image content */
    flex-direction: column;
    border-radius: 10px;
    position: relative;
}

.img-wrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Scale the image to fit within the wrapper */
    object-position: center; /* Center the image within the wrapper */
}

.img-wrapper .sw-buttons{
    position: absolute;
    bottom: 0;
    display: none;
}

.img-wrapper .sw-buttons button{
    font-size: 0.9rem;
    padding: 0.1rem 0.8rem;
    color: blue;
    border: none;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    margin: 0 3px 0 3px;

}

.img-wrapper .sw-buttons>:nth-child(1){
    background-color: #ffffff;
}

.img-wrapper .sw-buttons>:nth-child(2){
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
}



.m-card .details-wrapper{
    display: flex;
    width: 40%;
    height: 100%;
    flex-direction: column;
}


.m-card .details-wrapper .prText{
    color: #1f3e72;
    font-weight: bold;
    font-size: 1rem;
}

#light .details-wrapper .prText{
    color: #1f3e72;
}
#dark .details-wrapper .prText{
    color: #1c53b2;
}


#light .priceText{
    color: #1f3e72;

}

#dark .priceText{
    color: #1c53b2;

}


.m-card .id-wrapper{
    display: flex;
    width: 20%;
    height: 100%;
    flex-direction: column;
}


#light .id-wrapper .prText{
    color: black;
}


#dark .id-wrapper .prText{
    color: white;
}

.m-card span{
    margin-right: 2px;
    margin-top: 2px;
}
.m-card i{
    margin-right: 6px;
}

.m-card .edit {
    /* Set the initial scale value */
    transform: scale(1);
    /* Add a smooth transition for scaling effect with a duration of 0.3 seconds */
    transition: transform 0.3s ease;
}
  
.m-card .edit:hover {
    /* Apply the scaling effect on hover */
    transform: scale(1.2);
}

.m-card:hover{
    scale: 1.025;
    cursor: pointer;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(135, 160, 255, 0.46) 217.91%
    );

    box-shadow: 0px 72px 49px -51px rgba(136, 160, 255, 0.21);
}

.m-card:hover .img-wrapper .sw-buttons {
    display: flex; /* Show the buttons when hovering over m-card */
}




@media (max-width: 768px) {

    .m-card{
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        padding: 1rem;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        margin: auto;
        transition: all 300ms ease-in;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    
    
    
    .m-card .img-wrapper {
        display: flex;
        width: 15rem;
        height: 15rem; /* Set the desired height for the image wrapper */
        align-items: center; /* Center the image vertically */
        overflow: hidden; /* Hide any overflowing image content */
        flex-direction: column;
        border-radius: 10px;
        position: relative;
    }
    
    .img-wrapper > img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Scale the image to fit within the wrapper */
        object-position: center; /* Center the image within the wrapper */
    }
    
    

    .m-card .details-wrapper{
        display: flex;
        width: 100%;
        height: fit-content;
        flex-direction: column;
    }
    
    
    .m-card .details-wrapper .prText{
        color: #1f3e72;
        font-weight: bold;
        font-size: 1rem;
    }
    
    #light .details-wrapper .prText{
        color: #1f3e72;
    }
    #dark .details-wrapper .prText{
        color: #1c53b2;
    }
    
    
    #light .priceText{
        color: #1f3e72;
    
    }
    
    #dark .priceText{
        color: #1c53b2;
    
    }
    
    
    .m-card .id-wrapper{
        display: flex;
        width: 100%;
        height: fit-content;
        flex-direction: column;
    }


    
    #light .id-wrapper .prText{
        color: black;
    }
    
    
    #dark .id-wrapper .prText{
        color: white;
    }
    
    .m-card span{
        margin-right: 2px;
        margin-top: 2px;
    }
    .m-card i{
        margin-right: 6px;
    }
      
    
    
}