.dash-wrapper{
    display: flex;
    flex-direction: column;
}

.dash-wrapper .dash-headline{
    display: flex;
    width: 100vw;
    background: blue;
}

.dash-headline h1{
    padding: 10px;
}

.dash-wrapper .dash-content-wrapper{
    display: flex;
    flex-direction: row;
}

.dash-content-wrapper .dash-tabs{
    display: flex;
    flex-direction: column;
    border-right: 1px solid #acacac;
    height: 100;
    width: 15%;
}

.dash-tabs span{
    width: 100%;
    border-bottom: 1px solid #acacac;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
}

.dash-tabs .active{
    color: blue;
}

.dash-content-wrapper .dash-content{
    display: flex;
    flex-direction: column;
    width: 85%;
}

.dash-content .inner-content h1{
    background: grey;
}