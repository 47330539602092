.rp-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100vh;

}

.rp-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80rem;
  margin-top: 20px;
  margin-right: 5px;
}

.rp-container .upper-part {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32rem;
  padding-bottom: 20px;
  width: 90%;
}

.rp-container .mid-part {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 16rem;
  width: 90%;
}

.mid-part .half-part {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.half-part:nth-child(1) {
  padding-right: 10px;
}

.half-part:nth-child(2) {
  padding-left: 10px;
}

.rp-container .lower-part {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32rem;
  padding-top: 20px;
  width: 900%;
}

.rp-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity to control the darkness of the background */
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}

.modal {
  background-color: #fff;
  padding: 20px;
  max-width: 600px;
  max-height: 80vh;
  overflow: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.modal-overlay.show {
  opacity: 1;
  visibility: visible;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  z-index: 1;
}

.close:hover {
  color: #ccc;
}




@media(max-width:768px){

  .rp-wrapper {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    height: 60vh;
    width: 100%;
  }
  
  .rp-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 40rem;
    margin-top: 0;
    margin-right: 0;
  }
  
  .rp-container .upper-part {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
    width: 30rem;
  }
  
  .rp-container .mid-part {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 20rem;
  }
  
  .mid-part .half-part {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
    overflow: hidden;
    padding: 5px;
  }


  .rp-container .lower-part {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
    width: 30rem;
  }

}