
.dealtypedrop{
    display: flex;
    position: relative;
}

.dealtypedrop .dropdown-btn{
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    user-select: none;
    height: 70px;
    padding: 15px;
    white-space: nowrap;
    width: 220px;
    overflow: hidden;

}

.dealtypedrop .dropdown-btn:hover{
    background: rgb(242, 242, 242);
    transition: 150ms ease-in;
}

.dealtypedrop .dropdown-btn span {
    margin-right: 10px; /* Adjust the margin as per your preference */
    font-size: 0.8rem;
}


.dealtypedrop .dropdown-content{
    display: flex;
    position: absolute;
    top: 110%;
    left: 0;
    padding: 10px;
    font-weight: 500;
    font-size: 0.8rem;
    width: fit-content;
    flex-direction: column;
    border-radius: 20px;
    justify-content: center;
    align-items: start;
}



#dark .dropdown-content{
    background: #333;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    color: #fff;
}


#light .dropdown-content{
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    color: #333;
}


.dropdown-content .deal-content{
    display: flex;
    height: 45%;
    width: 100%;
    flex-direction: row;

}


.dropdown-content .property-content{
    display: flex;
    height: 45%;
    width: 100%;
    flex-direction: row;

}

.dropdown-content .button-space{
    display: flex;
    height: 10%;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}


.dealtypedrop .dropdown-content .dropdown-item{
    padding: 8px;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 30px;
    border: solid rgb(105, 105, 105) 1px;
    margin: 7px;
    white-space: nowrap;

}

.dropdown-item.selected{
    background-color: rgb(20, 20, 192);; /* Set your desired background color */
    color: #ffffff; /* Set your desired text color */
}

.dealtypedrop .dropdown-content .dropdown-item:hover{
    background: #b4b4b4;
}









.dropdown-content .line{
    width: calc(100% - 10px);
    height: 2px;
    border-bottom: 1px solid rgb(193, 193, 193);
    align-self: center;
    padding: 5px;
}


@media (max-width: 768px) {

    .dealtypedrop{
        display: flex;
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .dealtypedrop .dropdown-btn{
        height: 45px;
        width: 220px;
        justify-content: center;
    }

    .dealtypedrop .dropdown-btn span {
        margin-right: 0; /* Adjust the margin as per your preference */
        font-size: 0.8rem;
    }

    .dealtypedrop .dropdown-content{
        width: 100%;
        left: 0%;
        z-index: 10;
    }


    .dealtypedrop .dropdown-content .dropdown-item{
        padding: 5px;
        cursor: pointer;
        transition: all 0.2s;
        border-radius: 30px;
        border: solid rgb(105, 105, 105) 1px;
        margin: 3px;
        white-space: nowrap;
        font-size: 11px;
    
    }


    .dropdown-content .upper{
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
    }

    .dropdown-content .lower{
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
    }
}