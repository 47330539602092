.pricedrop{
    display: flex;
    position: relative;
    
}

.pricedrop .dropdown-btn{
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    height: 45px;
    white-space: nowrap;
    width: 120px;
    overflow: hidden;

}

.pricedrop .dropdown-btn:hover{
    background: rgb(242, 242, 242);
    transition: 150ms ease-in;
}

.pricedrop .dropdown-btn span {
    margin-right: 10px; /* Adjust the margin as per your preference */
    font-size: 0.7rem;

}




.pricedrop .dropdown-content{
    display: flex;
    position: absolute;
    top: 110%;
    left: 0;
    padding: 10px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);;
    font-weight: 500;
    color: #333;
    width: fit-content;
    flex-direction: column;
    border-radius: 20px;
    justify-content: center;
    align-items: start;
}

.dropdown-content .top{
    display: flex;
    height: 80%;
    width: 100%;
    flex-direction: row;
    padding-top: 10px;
}

.dropdown-content .bottom{
    display: flex;
    height: 20%;
    width: 100%;
    padding: 15px;
    justify-content: center;
}

.left{
    display: flex;
    height: 100%;
    width: 50%;
}

.right{
    display: flex;
    height: 100%;
    width: 50%;
}


.dropdown-content .price-input{
    height: 40px;
    width: 150px;
    border-radius: 10px;
    border: solid grey 0.5px;
    margin: 5px;
}


.hor-line{
    width: calc(100% - 10px);
    height: 2px;
    border-bottom: 1px solid rgb(216, 216, 216);
    align-self: center;
    padding: 5px;
}

.top input::placeholder{
    font-size: 10px;
    padding-left: 5px;
}



@media (max-width: 768px){


    .pricedrop{
        display: flex;
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: center;
    }


    .pricedrop .dropdown-content{
        display: flex;
        position: absolute;
        top: 110%;
        left: -90%;
        padding: 10px;
        background: #fff;
        box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);;
        font-weight: 500;
        color: #333;
        width: fit-content;
        flex-direction: column;
        border-radius: 20px;
        justify-content: center;
        align-items: start;
    }
    

}