.add-statement-wrapper {
    display: flex;
    width: 100%;
}
  
.add-statement-wrapper .add-statement-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}
  
.add-statement-content .type-drop {
    position: relative;
    display: inline-block;
}
  
.add-statement-content .drop-button {
    background-color: #f1f1f1;
    color: #333;
    padding: 10px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    width: 200px;
    border-radius: 30px;
    margin: 5px;
}
  
.add-statement-content .drop-content {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 8px;
    min-width: 160px;
}

.add-statement-content .address-input{
    width: 300px;
    height: 40px;
    border-radius: 30px;
    padding: 10px;
}

.add-statement-content .double-input{
    width: 150px;
    height: 40px;
    border-radius: 30px;
    padding: 10px;
}

.add-statement-content .checkbox-title {
    margin-right: 10px;
}
  
.add-statement-content .checkbox-label {
    align-items: center; /* Center vertically */
    margin-right: 15px;
    cursor: pointer;
}
  
.add-statement-content .main-checkbox {
    position: absolute;
    opacity: 0;
}
  
.add-statement-content .custom-checkbox {
    display: inline-flex; /* Use inline-flex to center the checkmark */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 30px;
    height: 30px;
    border: 2px solid #ccc;
    margin-right: 5px;
    background-color: #fff;
    cursor: pointer;
    border-radius: 15px; /* Optional: To add rounded corners */
    vertical-align: middle; /* Add this line to center the checkbox vertically */
    margin-bottom: 10px;
    margin-top: 10px;
}
  
.add-statement-content .main-checkbox:checked + .custom-checkbox {
    background-color: #007bff; /* Color when checkbox is checked */
    border-color: #007bff;
}
  
/* Optional: Style the checkmark when checkbox is checked */
.add-statement-content .main-checkbox:checked + .custom-checkbox::after {
    display: block;
    text-align: center;
    color: #fff;
}









.add-statement-content .checkbox-container {
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
}
  
.add-statement-content .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
.add-statement-content .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 3px;
    
}
  
.add-statement-content .checkbox-container:hover input ~ .checkmark {
    background-color: #f1f1f1;
}
  
.add-statement-content .checkbox-container input:checked ~ .checkmark {
    background-color: #007bff;
    border-color: #007bff;
}
  
.add-statement-content .checkmark:after {
    content: "\2713";
    display: none;
    text-align: center;
    color: #fff;
}
  
.add-statement-content .checkbox-container input:checked ~ .checkmark:after {
    display: block;
}
  
.add-statement-content .checkbox-container .checkmark:after {
    padding-top: 2px;
}






.add-statement-content .area-price-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-statement-content .comment-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-statement-content .comment-wrapper{
    height: 150px;
    width: 350px;
    margin: 10px;
    border-radius: 30px;
    padding: 10px;
    resize: none;
}





.image-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .image-input input {
    display: none;
  }
  
  .image-upload-label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: block;
  }
  
  .image-preview {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .image-preview img {
    width: 200px;
    height: 220px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  
  .no-images-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    color: #888;
    font-size: 14px;
  }
  


  
.add-statement-content .drop-content span {
    display: block;
    padding: 8px 12px;
    text-decoration: none;
    color: #333;
    font-size: 14px;
}
  
.add-statement-content .drop-content span:hover {
    background-color: #f1f1f1;
}
  
.add-statement-content .show {
    display: block;
}
  



/*Sections */


/*Common Information*/
.add-statement-content .common-information{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/*Characteristics*/
.add-statement-content .detailed-information{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/*Parameters */
.add-statement-content .parameters-information{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


/*Error*/
.error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .drop-button.error {
    border: 1px solid red;
  }