.inner-container{
    gap: 1.5rem;
    background: #e5e5e5;
    padding: 2rem;
    border-radius: 10px;
    border: 3px solid #1a1c20;
    text-align: center;
}

.inner-container .primaryText{
    color: #1f3e72;
    font-weight: 600;
}

.inner-container .secondaryText{
    color: rgb(140 139 139)
}

.inner-container .button{
    background-color: black;
    box-shadow: var(--shadow);
    border-radius: 10px;
}