

#time {
  position: absolute;
  right: 100vw;
}
#time + label {
  --i: 0;
  --j: calc(1 - var(--i));
  display: grid;
  grid-gap: 0.5em 0.25em;
  overflow: hidden;
  padding: 0.5em;
  height: 2em;
  width: 70%;
  border-radius: 1.75em;
  background: hsl(199, 98%, calc(var(--j) * 48%));
  color: transparent;
  user-select: none;
  transition: 0.3s;
  cursor: pointer;
}
#time + label:before, #time + label:after {
  width: 1em;
  height: 1em;
  transition: inherit;
  content: "";
}
#time + label:before {
  transform-origin: 10% 40%;
  transform: translate(calc(var(--i) * (100% + 0.25em))) scale(calc(1 - var(--i) * 0.7));
  background: hsl(60deg, 100%, 50%);
  border-radius: 50px;
}
#time + label:after {
  grid-column: 2;
  border-radius: 50%;
  transform: translatey(calc(var(--i) * (-100% - 0.5em)));
  background: radial-gradient(circle at 19% 19%, transparent 41%, #fff 43%);
}
#time:checked + label {
  --i: 1 ;
}

#time {
  display: none;
}