.lp-wrapper .address .main-address{
    padding-top: 10px;
    font-size: 30px;
    padding-bottom: 3px;
}

#light .main-address{
    color: black;
}

#dark .main-address{
    color: white;
}

.lp-wrapper .address .specific-address{
    font-size: 12px;
    color: grey;
}

.lp-wrapper .area-wrapper{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.area-wrapper .area-item{
    display: flex;
    flex-direction: row;
    margin-right: 30px;
}

#light .area-item{
    color: black;
}

#dark .area-item{
    color: white;
}

.area-item span{
    margin-left: 8px;
}



.lp-wrapper .price{
    font-size: 20px;
    margin-top: 20px;
}

#light .price{
    color: black;
}

#dark .price{
    color: white;
}


.lp-wrapper .i-stats{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.i-stats .icon-cont{
    width: 25%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-right: 30px;
}

.icon-cont .img-cont {
    display: flex;
    align-items: flex-end;
    width: 40px;
    height: 40px;
    overflow: hidden;
}


.img-cont img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
  
.icon-cont p {
    margin-top: 5px;
    font-size: 12px;
}

#dark .icon-cont p{
    color: white;
}

#light .icon-cont p{
    color: black;
}


.desc {
    display: flex;
    width: 70%;
    color: grey;
    margin-top: 30px;
    font-size: 15px;
    margin-bottom: 10px;
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 20px; /* Initial height */
}
  
.desc.expanded {
    max-height: 500px; /* Expanded height */
    transition: max-height 2s ease;
}

#light .read-more{
    color: black;
}

#dark .read-more{
    color: white;
}

.lower-stats{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30rem;
    margin-top: 30px;
}

.lower-stats .down-row{
    display: flex;
    flex-direction: column;  
    padding-bottom: 10px;
}

#light .down-row h2{
    color: black;
}

#dark .down-row h2{
    color: white;
}

.down-row .down-row-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}


#light .down-row .down-row-item{
    color: black;
}

#dark .down-row .down-row-item{
    color: white;
}



.down-row-item.negative {
    text-decoration: line-through;
}


#light .down-row-item.negative{
    color: rgb(171, 171, 171);
}

#dark .down-row-item.negative{
    color: rgb(69, 69, 69);
}

.down-row-item p{
    font-size: 13px;
    margin-left: 5px;
}



.img-container{
    width: 25px;
    height: 25px;
    overflow: hidden;
}

.img-container img{
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
}








@media(max-width:768px){

    .lp-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        width: 100%;
        padding: 10px;
    }


    .lower-stats{
        width: 100%;
    }




}