/* Initially, display two columns */
.cl-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

/* Adjust to one column on screens with a maximum width of, for example, 768px (adjust this value as needed) */
@media (max-width: 768px) {
  .cl-cards {
    grid-template-columns: 1fr;
  }

  .cl-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}