.listing-details-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    margin-top: 5%;
    margin-bottom: 2%;

}

.listing-details-wrapper .left-panel{
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
    border-radius: 10px;
    
}


#light .left-panel{
    background: rgba(242, 242, 242, 0.8);

}

#dark .left-panel{
    background: rgba(33, 33, 33, 0.8);

}

.listing-details-wrapper .right-panel{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 5%;

}




@media (max-width: 768px){

    .listing-details-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        width: 100%;
        height: 100%;
        margin-top: 5%;
        margin-bottom: 2%;
    
    }
    .listing-details-wrapper .left-panel {
        display: flex;
        flex-direction: column;
        width: 95%;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-left: 0;
        border-radius: 10px;
        
    }

    .listing-details-wrapper .right-panel {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 0;
    
    }
    

}