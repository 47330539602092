.warning-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}


#light .warning-wrapper .warning{
    color: black;
}


#dark .warning-wrapper .warning{
    color: white;
}