.h-wrapper{
    color: black;
    z-index: 99;
}


#light .h-wrapper{
    background: rgba(242, 242, 242, 0.8);
    border-bottom: 2px solid rgb(235, 235, 235);
    transition: 300ms background-color ease-in-out, 300ms border-bottom ease-in-out;
}

#dark .h-wrapper{
    background: rgba(33, 33, 33, 0.8);
    border-bottom: 2px solid #1c1c1c;
    transition: 300ms background-color ease-in-out, 300ms border-bottom ease-in-out;

}



.h-container{
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--secondary);
}

.logo-link{
    cursor: pointer;
    z-index: 20;
}

.logo-link img{
    height: 90px;
    width: 90px;
}

.h-menu{
    gap: 2rem;
}

.h-menu>*{
    transition: color 300ms ease-in-out;
    cursor: pointer;
}


#light .h-menu>*{
    color: rgba(33, 33, 33, 0.8);
}

#dark .h-menu>*{
    color: rgba(242, 242, 242, 0.8);
}


#light .h-menu>*:hover{
    color: #FFA44F;
    transition: color 300ms ease-in-out;
}

#dark .h-menu>*:hover{
    color: #FFA44F;
    transition: color 300ms ease-in-out;
}



.menu-icon{
    display: none;
}




@media (max-width: 768px){
    .menu-icon{
        display: block;
    }

    #dark .menu-icon{
        color: white;
    }

    #light .menu-icon{
        color: black;
    }

    .h-menu{
        z-index: 99;
        color: var(--black);
        position: absolute;
        top: 3rem;
        right: 4rem;
        background: white;
        flex-direction: column;
        font-weight: 500;
        gap: 2rem;
        padding: 3rem;
        border-radius: 10px;
        align-items: flex-start;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.005);
        transition: all 300ms ease-in-out;
    }


    #light .h-menu>*{
        color: rgba(33, 33, 33, 0.8);
    }
    
    #dark .h-menu>*{
        color: rgba(0, 0, 0, 0.8);
    }
}