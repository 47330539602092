.login-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.login-wrapper .login-container{
    border: solid black 1px;
}


.login-container .form-container{
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.form-container input{
    margin-bottom: 10px;
}