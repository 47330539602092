.popular-statements-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    width: 100%;
    flex-direction: column;
}

.popular-add-btn{
    height: 40px;
    width: 200px;
    cursor: pointer;
    margin-top: 20px;
}

.popular-add-btn:hover{
    background: blue;
}


