.locationdrop{
    display: flex;
    position: relative;

}

.locationdrop .dropdown-btn{
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    height: 45px;
    white-space: nowrap;
    width: 220px;
    overflow: hidden;

}

.locationdrop .dropdown-btn:hover{
    background: rgb(242, 242, 242);
    transition: 150ms ease-in;
}

.locationdrop .dropdown-btn span {
    margin-right: 10px; /* Adjust the margin as per your preference */
    font-size: 0.8rem;
}


.locationdrop .dropdown-content{
    display: flex;
    position: absolute;
    top: 110%;
    left: 0;
    padding: 10px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);;
    font-weight: 500;
    color: #333;
    flex-direction: row;
    border-radius: 20px;
    justify-content: center;
    align-items: start;
    font-size: 0.8rem;
    white-space: nowrap;
    width: fit-content;
    height: 300px;
    text-align: center;

}

.locationdrop .dropdown-content .left{
    display: flex;
    height: 100%;
    width: fit-content;
    flex-direction: column;
    padding-right: 10px;
}

.locationdrop .dropdown-content .center{
    display: flex;
    height: 100%;
    width: fit-content;
    flex-direction: column;
    overflow-y: auto;
}

.locationdrop .dropdown-content .right{
    display: flex;
    height: 100%;
    width: fit-content;
    flex-direction: row;
    overflow-y: auto;
}


.city-part{
    overflow-y: auto;
}

.district-part{
    overflow-y: auto;

}

.urban-part-col1{

}
.urban-part-col2{

}
.urban-part-col3{

}


.locationdrop .line-ver{

    width: 2px;
    height: calc(100% - 10px);
    border-left: 1px solid rgb(193, 193, 193);
    align-self: center;
    padding: 5px;

}


.locationdrop .dropdown-content .dropdown-item{
    padding: 8px;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 30px;
    border: solid rgb(105, 105, 105) 1px;
    margin: 7px;
    white-space: nowrap;

}

.dropdown-item.selected{
    background-color: rgb(20, 20, 192);; /* Set your desired background color */
    color: #ffffff; /* Set your desired text color */
}

.dealtypedrop .dropdown-content .dropdown-item:hover{
    background: #b4b4b4;
    
}






::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	z-index: 9999;
	width: 6px;
	height: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #000000;
}





@media (max-width: 768px){


    .locationdrop{
        display: flex;
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: center;
    }


    .locationdrop .dropdown-content{
        width: 100%;
        left: 0%;
        z-index: 10;
    }


    .locationdrop .dropdown-content .left{
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        padding-right: 0;
    }
    
    .locationdrop .dropdown-content .center{
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        overflow-x: hidden;
    }

    .locationdrop .dropdown-content .right{
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        overflow-x: hidden;
    }


    .locationdrop .dropdown-content .dropdown-item{
        padding: 2px;
        cursor: pointer;
        transition: all 0.2s;
        border-radius: 30px;
        border: solid rgb(105, 105, 105) 1px;
        margin: 5px;
        white-space: nowrap;
    
    }

    .locationdrop .line-ver{

        width: 2px;
        height: calc(100% - 10px);
        border-left: 1px solid rgb(193, 193, 193);
        align-self: center;
        padding: 0;
    
    }
}