

#light .hero{
    background: rgb(242, 242, 242);
    transition: 300ms background ease-in-out;
}

#dark .hero{
    background: rgb(33, 33, 33);
    transition: 300ms background ease-in-out;

}

.hero-wrapper{
    color: white;
    position: relative;
    padding-bottom: 2rem;
    z-index: 1;
}

.hero-container{
    justify-content: space-around;
    align-items: flex-end;
}

/* Left side */

.hero-left{
    gap: 3rem;
}

.hero-title{
    position: relative;
    z-index: 1;
}

#light .hero-title{
    color: rgba(33, 33, 33, 0.8);
}

#dark .hero-title{
    color: rgba(242, 242, 242, 0.8);
}

.hero-title>h1{
    font-weight: 600;
    font-size: 3.3rem;
    line-height: 4rem;
    text-align: left;
}

.orange-circle{
    height: 4rem;
    width: 4rem;
    background: var(--orange-gradient);
    border-radius: 999px;
    position: absolute;
    right: 25%;
    top: -10%;
    z-index: -1;
}

.search-button{
    border-radius: 5px;
    width: 200px;
    height: 40px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
}


#light .search-button{
    background-color: rgba(242, 242, 242, 0.8);
    color: rgba(33, 33, 33, 0.8);
    border: 1px solid black;
}

#dark .search-button{
    background-color: rgba(33, 33, 33, 0.8);
    color: rgba(242, 242, 242, 0.8);
    border: 1px solid white;
}


#light .search-button:hover{
    background-color: #FFA44F;
    color: black;
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
}


#dark .search-button:hover{
    background-color: #FFA44F;
    color: black;
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
}


.stats{
    width: 100%;
    justify-content: space-between;
}


#light .stats{
    color: rgba(33, 33, 33, 0.8);
}

#dark .stats{
    color: rgba(242, 242, 242, 0.8);

}

.stat>:nth-child(1){
    font-size: 2rem;
}

.stat>:nth-child(1)>:last-child{
    color: orange;
}



/* Right side */

.image-container{
    width: 30rem;
    height: 35rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    border: 8px solid rgba(255, 255, 255, 0.12)

}


#light .image-container{
    border: 8px solid rgba(15, 15, 15, 0.12)
}


#dark .image-container{
    border: 8px solid rgba(255, 255, 255, 0.12)
}


.image-container>img{
    width: 100%;
    height: 100%;

}



@media(max-width: 640px){
    .hero-container{
        margin-top: 2rem;
    }
    .hero-title>h1{
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .image-container{
        width: 95%;
        height: 25rem;
    }
    .stats{
        justify-content: center;
        gap: 1.5rem;
    }
    .hero-right{
        width: 100%;
    }
    .stat>:nth-child(1){
        font-size: 1.5rem;
    }
    .stat>:nth-child(2){
        font-size: 0.8rem;
    }
}